<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content
        class="d-flex flex-column align-content-space-between align-center"
      >
        <div class="d-flex justify-center align-center">
          <p class="display-1 text--primary align-center d-flex ma-0 pa-0 mt-4 mr-2">
            {{ $t("menu.company") }}
          </p>
          <UserOptions module="company" :type="'CONFIG'" />
        </div>

        <v-hover v-if="!loading && (companyById.length > 0 && companyById[0].logo)">
          <template v-slot:default="{ hover }">
            <v-img
              v-if="companyById.length > 0 && companyById[0].logo"
              alt="company_logo"
              aspect-ratio="2"
              width="150"
              max-width="200"
              contain
              :lazy-src="companyById[0].logo.s3_url"
              :src="companyById[0].logo.s3_url"
            >
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        fab
                        color="white"
                        :loading="loadingDeleteLogo"
                        v-on="on"
                      >
                        <template v-slot:loader>
                          <v-progress-circular
                            color="secondary"
                            indeterminate
                            size="16"
                            width="2"
                          >
                          </v-progress-circular>
                        </template>
                        <v-icon
                          color="error"
                          :loading="loadingDeleteLogo"
                          @click="deleteLogo()"
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span style="font-size: 10px">
                      {{ $t("general.buttons.delete") + " LOGO" }}
                    </span>
                  </v-tooltip>
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </template>
        </v-hover>
        <div
          v-else-if="!loading && (companyById.length > 0 && companyById[0].logo)"
          class="d-flex justify-center align-center"
          style="width: 140px; height: 140px"
        >
          <v-progress-circular
            color="secondary"
            indeterminate
            size="20"
            width="2"
          >
          </v-progress-circular>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :items="companyById"
      class="agTable"
      :loading="loading"
      :search="search"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Company', permisions: ['Update'] }])"
              color="neuter"
              class="mouse-pointer mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.edit") }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t("menu.company") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'QrAdmin', permisions: ['Update'] }])"
              color='neuter'
              @click.stop='manageQR(item)'
              class="mouse-pointer"
              size="14"
            >
              mdi-qrcode-edit
            </v-icon>
          </template>
          <span>
            {{ $t('locations.titles.manageQr') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t("menu.company") }}
            </span>
          </span>
        </v-tooltip>
        <!--<v-icon
          small
          @click="deleteItemDialog(item)"
        >
          mdi-delete
        </v-icon>-->
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog persistent v-model="dialog" max-width="500px">
        <v-card :light="true" color="white">
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      onkeypress="return (event.charCode != 34)"
                      color="secondary"
                      v-on:keyup.86="replaceName"
                      v-on:keyup.ctrl.86="replaceName"
                      v-model="editedItem.name"
                      required
                      outlined
                      dense
                      class="required"
                      :rules="requireAndMaxRules(max)"
                      :counter="max"
                      maxlength="max"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.name") }}
                        <span class="red--text"><strong>*</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      onkeypress="return (event.charCode != 34)"
                      color="secondary"
                      v-on:keyup.86="replacePrefixGS1"
                      v-on:keyup.ctrl.86="replacePrefixGS1"
                      v-model="editedItem.prefixGS1"
                      required
                      outlined
                      dense
                      class="required"
                      :rules="requireAndMaxRules(maxPrefix)"
                      :counter="maxPrefix"
                      maxlength="maxPrefix"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.prefixGs1") }}
                        <span class="red--text"><strong>*</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      onkeypress="return (event.charCode != 34)"
                      color="secondary"
                      v-on:keyup.86="replaceEmail"
                      v-on:keyup.ctrl.86="replaceEmail"
                      v-model="editedItem.email"
                      required
                      outlined
                      dense
                      class="required"
                      :rules="requireRules"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.email") }}
                        <span class="red--text"><strong>*</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      onkeypress="return (event.charCode != 34)"
                      color="secondary"
                      v-on:keyup.86="replaceDocumentKey"
                      v-on:keyup.ctrl.86="replaceDocumentKey"
                      v-model="editedItem.document_key"
                      required
                      outlined
                      dense
                      class="required"
                      :rules="requireRules"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.identificationType") }}
                        <span class="red--text"><strong>*</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      onkeypress="return (event.charCode != 34)"
                      color="secondary"
                      v-on:keyup.86="replaceDocumentValue"
                      v-on:keyup.ctrl.86="replaceDocumentValue"
                      v-model="editedItem.document_value"
                      required
                      outlined
                      dense
                      class="required"
                      :rules="requireRules"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.identificationValue") }}
                        <span class="red--text"><strong>*</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      onkeypress="return (event.charCode != 34)"
                      color="secondary"
                      v-on:keyup.86="replaceTelephone"
                      v-on:keyup.ctrl.86="replaceTelephone"
                      v-model="editedItem.telephone"
                      required
                      outlined
                      dense
                      class="required"
                      :rules="requireRules"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.telephone") }}
                        <span class="red--text"><strong>*</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      required
                      class="required"
                      :rules="onlyImageRules(fileSelected)"
                      show-size
                      :placeholder="
                        loadingLogo ? $t('general.titles.loading') : ''
                      "
                      :loading="loadingLogo"
                      v-model="fileSelected"
                      color="secondary"
                      prepend-inner-icon="mdi-camera"
                      prepend-icon=""
                      small-chips
                      outlined
                      dense
                      @change.native="changeLogo"
                    >
                      <template v-slot:label>
                        {{ $t("documents.fields_add.file") }}
                        <span class="red--text"><strong>*</strong></span>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="loadingSave"
              small
              tile
              outlined
              color="gray"
              @click="close"
            >
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn
              small
              tile
              outlined
              color="secondary"
              :loading="loadingSave"
              :disabled="!valid"
              @click="save"
            >
              {{ $t("general.buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small text color="secondary" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from "@/plugins/i18n"
  import { mapActions, mapGetters } from "vuex"
  import PermisionService from "../../../services/PermisionService"
  import CompactImages from "../../../services/CompactImages"
  import axios from "axios"
  import DocumentsService from "../../../services/DocumentsService"
  import jsZip from "jszip"
  import CompanyService from "../../../services/CompanyService"
  import UserOptions from "../../../components/UserOptions/IndexComponent"
  import _ from "lodash";

  const MIME_TYPE = {
    jpeg: "image/jpeg",
    jpg: "image/jpg",
    bmp: "image/bmp",
    png: "image/png",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    doc: "application/msword",
    csv: "text/csv",
    pdf: "application/pdf",
    zip: "application/zip",
    xml: "text/xml",
  }

  export default {
    components: { UserOptions },
    data: () => ({
      langs: [],
      profile: null,
      dialog: false,
      editedIndex: -1,
      fileEditChange: false,
      search: "",
      editedItem: {
        name: "",
        email: "",
        document_key: "",
        document_value: "",
        telephone: "",
        prefixGS1: "",
      },
      defaultItem: {
        name: "",
        email: "",
        document_key: "",
        document_value: "",
        telephone: "",
        prefixGS1: "",
      },
      fileSelected: null,
      loadingLogo: false,
      loadingSave: false,
      loadingDeleteLogo: false,
      showDialog: false,
      title: "Información",
      dialogText: "Añada un texto a este dialogo",
      valid: false,
      max: 40,
      maxPrefix: 10,
      deletingItem: {},
    }),

    computed: {
      ...mapGetters({
        companies: 'company/userCompanies',
        companyById: "company/companyById",
        authority: "authority/authority",
        loading: "company/loading",
        requireAndMaxRules: "general/requireAndMaxRules",
        requireRules: "general/requireRules",
        onlyImageRules: "general/onlyImageRules",
        configUser: "general/config",
      }),
      headers: () => [
        { text: i18n.t("company.fields.name"), value: "name" },
        { text: i18n.t("company.fields.prefixGs1"), value: "prefixGS1" },
        { text: i18n.t("company.fields.email"), value: "email" },
        {
          text: i18n.t("company.fields.identificationType"),
          value: "document_key",
        },
        {
          text: i18n.t("company.fields.identificationValue"),
          value: "document_value",
        },
        { text: i18n.t("company.fields.telephone"), value: "telephone" },
        {
          text: i18n.t("general.fields.action"),
          value: "action",
          width: 100,
          sortable: false,
          align: "center",
        },
      ],
      formTitle() {
        return (
          (this.editedIndex === -1
            ? i18n.t("general.titles.new")
            : i18n.t("general.titles.edit")) +
          " " +
          i18n.t("menu.company")
        )
      },
    },

    watch: {
      dialog(val) {
        val || this.close()
      },
    },

    async created() {
      await this.initialize()
    },

    methods: {
      ...mapActions({
        fetchCompany: "company/fetchCompany",
        fetchAuthority: "authority/fetchAuthority",
        update: "company/update",
        delete: "company/delete",
        deleteCompany: "company/deleteCompany",
        fetchAvailableCompanies: "company/fetchAvailableCompanies",
      }),
      async initialize() {
        this.profile = JSON.parse(localStorage.getItem("profile"))
        this.langs = this.$i18n.availableLocales
        await this.fetchCompany([this.profile, this.$toast])
      },
      manageQR(item) {
        const token = localStorage.getItem('token');
        const owner = this.profile.company_owner;
        window.open(
          encodeURI(`${this.profile.qrDomain}/admin/qr?owner=${owner}&type=qrg&token=${token}`),
          '_blank'
        );
      },
      changeLogo() {
        if (this.editedItem.logo)
          this.editedItem.logo.update_logo = !!(
            this.editedIndex !== -1 && this.editedItem.logo
          )
      },

      async editItem(item) {
        this.fileEditChange = false
        this.editedIndex = this.companyById.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (!this.editedItem.logo) {
          this.editedItem.logo = {
            update_logo: false,
          }
        }

        this.dialog = true

        this.loadingLogo = true
        await CompanyService.downloadLogo(this.editedItem)
          .then(response => {
            let type = MIME_TYPE[this.editedItem.logo.file_extension]

            const blob = new Blob([response.data], {
              type: "application/zip",
            })

            this.fileSelected = new File(
              [blob],
              `logo-${this.editedItem.name}.${this.editedItem.logo.file_extension}`,
              {
                type: type,
              }
            )
          })
          .catch(e => {
            // this.$toast.success(
            //   i18n.t('company.notification.logoError'),
            //   {
            //     color: 'error',
            //     queueable: true,
            //   }
            // )
          })
          .finally(() => {
            this.loadingLogo = false
          })
      },
      async deleteLogo() {
        this.loadingDeleteLogo = true
        await this.delete([this.profile.company_id, this.$toast]).finally(
          async () => {
            const current = JSON.parse(localStorage.getItem("company:current"));
            localStorage.setItem('company:current', JSON.stringify(this.companies.find(c => c.identity === current.identity)))
            await this.$store.dispatch('login/loadInitData').finally(() => {
              window.location.reload();
            });
          }
        )
      },
      async clearLogo() {
        this.editedItem.logo = null;
      },
      check(permisions) {
        return PermisionService.check(permisions)
      },
      deleteItem() {
        this.dialogClose()
        this.deleteCompany([this.deletingItem.id, this.$toast]).finally(() => {
          this.fetchCompany([this.profile, this.$toast])
        })
      },

      close() {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.fileSelected = null
        }, 300)
      },
      async save() {
        this.loadingSave = true
        let item = Object.assign({}, this.editedItem)
        await this.update([item, this.fileSelected, this.$toast]).finally(
          async () => {
            await this.fetchCompany([this.profile, this.$toast])
            await this.fetchAuthority([this.profile.id, this.$toast])
            await this.$store.dispatch('login/loadInitData')

            await this.$store.dispatch('login/loadInitData').finally(() => {
              this.loadingSave = false
              this.dialog = false
              window.location.reload();
            });

            // localStorage.setItem("profile", JSON.stringify(this.authority))
            // this.$root.$emit('update:company', this.authority);
          }
        )
      },
      dialogShow(params) {
        this.title = params.title
        this.dialogText = params.dialogText
        this.showDialog = true
      },
      dialogClose() {
        this.showDialog = false
      },
      deleteItemDialog(item) {
        this.deletingItem = item
        this.dialogShow({
          title: i18n.t("general.titles.alert"),
          dialogText: i18n.t("general.notification.deleteItems"),
        })
      },
      replaceName(event) {
        this.editedItem.name = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replacePrefixGS1(event) {
        this.editedItem.prefixGS1 = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceEmail(event) {
        this.editedItem.email = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceDocumentKey(event) {
        this.editedItem.document_key = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceDocumentValue(event) {
        this.editedItem.document_value = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceTelephone(event) {
        this.editedItem.telephone = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
    },
  }
</script>

<style scoped></style>
